import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { locales } from "@energylab/shared-components/constants/config/routeConfig";
import * as React from "react";
import * as environment from "environment";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { countries } from "./countries";
import { departments } from "./departments";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            title: "auth.fields.birthDate",
            type: "date",
            property: "birthDate",
            required: true,
            disabled: false,
            validationRules: [
                {
                    validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
                }
            ]
        },
        {
            title: "auth.fields.country",
            type: "select",
            options: countries.map(country => ({ title: <FormatMessage id={`country.${country.code}`} />, value: country.code })),
            hide: false,
            property: "country",
            required: true
        },
        {
            title: "auth.fields.department",
            type: "cascader",
            property: "department",
            options: departments,
            required: true
        },
        {
            title: "auth.fields.selectedLanguage",
            type: "select",
            options: locales.map(language => (
                {
                    title: <FormatMessage id={`locale.${language}`} />,
                    value: language
                }
            )),
            property: "locale",
            required: true
        },
        {
            title: "auth.fields.sex",
            type: "radio",
            options: [{ title: <FormatMessage id="male" />, value: "male" }, { title: <FormatMessage id="female" />, value: "female" }],
            property: "sex",
            required: true
        }
    ]
};
