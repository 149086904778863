export interface Country {
    code: string;
    name: string;
}

export const unsortedCountries = [
    { code: "BEL", name: "Belgium" },
    { code: "NLD", name: "Netherlands" }
];

export const countries = unsortedCountries.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
});
