import { dashboardPlacesConfig as defaultDashboardPlacesConfig } from "@energylab/shared-components/constants/config/dashboardPlacesConfig";
import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { ambassadorSettingsConfig } from "./constants/config/ambassadorSettingsConfig";
import { articleTagsConfig } from "./constants/config/articleTagsConfig";
import { auth } from "./constants/config/authConfig";
import { challengeConfig } from "./constants/config/challengeConfig";
import { coinsConfig } from "./constants/config/coinsConfig";
import { commentsConfig } from "./constants/config/commentsConfig";
import { contactConfig } from "./constants/config/contactConfig";
import { contentConfig } from "./constants/config/contentConfig";
import { departmentsConfig } from "./constants/config/departmentsConfig";
import { eventsConfig } from "./constants/config/eventsConfig";
import { footerConfig } from "./constants/config/footerConfig";
import { galleryConfig } from "./constants/config/galleryConfig";
import { likesConfig } from "./constants/config/likesConfig";
import { logoutConfig } from "./constants/config/logoutConfig";
import { nationalities } from "./constants/config/nationalitiesConfig";
import { navbarConfig } from "./constants/config/navbarConfig";
import { newUsersConfig } from "./constants/config/newUsersConfig";
import { nicknameConfig } from "./constants/config/nicknameConfig";
import { notificationsConfig } from "./constants/config/notificationsConfig";
import { profileConfig } from "./constants/config/profileConfig";
import { providers } from "./constants/config/providersConfig";
import { registerConfig } from "./constants/config/registerConfig";
import { resetPasswordConfig } from "./constants/config/resetPasswordConfig";
import { routeConfig } from "./constants/config/routeConfig";
import { seo } from "./constants/config/seoConfig";
import { socialConfig } from "./constants/config/socialConfig";
import { webshopConfig } from "./constants/config/webshopConfig";

const locales = ["nl", "fr"];
const defaultLocale = "nl";

export const config: ConfigReducerState = {
    navbarConfig,
    leaderboardConfig: {
        pageSize: 10
    },
    registerConfig,
    profileConfig,
    enableCountry: true,
    departmentsConfig,
    dashboardPlacesConfig: {
        ...defaultDashboardPlacesConfig,
        homePage: ["dashboard"]
    },
    hideSocialLogin: true,
    hideSocialLinks: true,
    dashboardTopPadding: "30px",
    socialConfig,
    ambassadorImage: "",
    ambassadorSettingsConfig,
    auth,
    commentsConfig,
    eventsConfig,
    contentConfig,
    contactConfig,
    companyLogo: "https://res.cloudinary.com/energylab/image/upload/v1564998838/g4s/E2019_0294_A3.svg",
    companyName: "G4S",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig,
    socialTags: ["g4sv4you", "g4sbelgium", "g4sstars"],
    likesConfig,
    webshopConfig,
    notificationsConfig,
    providers,
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig,
    nationalities,
    selectedLanguage: getBrowserLocale(defaultLocale, locales),
    seo,
    uploadPreset: "g4sv4y",
    newUsersConfig,
    articleTagsConfig,
    nicknameConfig,
    footerConfig,
    challengeConfig,
    galleryConfig,
    logoutConfig,
    resetPasswordConfig,
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    socialShareConfig: {
        enabled: false,
        copyClipboardEnabled: false,
        shareWithEmail: {
            enabled: false
        }
    },
    leaderboardConfig: {
        pageSize: 10
    },
    whiteListSignUpConfig: {
        enabled: false,
        imageUrl: "https://res.cloudinary.com/energylab/image/upload/v1561723053/generic-v2/contactbg.jpg",
        subjectOptions: [],
        siteKey: whiteListSignUpConfig.siteKey
    },
    referralConfig: {
        enabled: true
    },
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "department"
    ]
};
