import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";

export const departments = [{
    value: "BE07",
    label: <FormatMessage id="department.BE07" />,
    children: [
        {
            value: "NSC",
            label: <FormatMessage id="department.NSC" />
        },
        {
            value: "Events",
            label: <FormatMessage id="department.Events" />
        },
        {
            value: "EuropeanCommission",
            label: <FormatMessage id="department.EuropeanCommission" />
        },
        {
            value: "GuardingCenter",
            label: <FormatMessage id="department.GuardingCenter" />
        },
        {
            value: "GuardingNorth",
            label: <FormatMessage id="department.GuardingNorth" />
        },
        {
            value: "GuardingSouth",
            label: <FormatMessage id="department.GuardingSouth" />
        },
        {
            value: "SalesMarketingAcademyQualityBD",
            label: <FormatMessage id="department.SalesMarketingAcademyQualityBD" />
        },
        {
            value: "HRAndPrevention",
            label: <FormatMessage id="department.HRAndPrevention" />
        },
        {
            value: "FinanceAndadminAndLegalAndFacilityAndProcurement",
            label: <FormatMessage id="department.FinanceAndadminAndLegalAndFacilityAndProcurement" />
        },
        {
            value: "IT",
            label: <FormatMessage id="department.IT" />
        }
    ]
},
{
    value: "BE09",
    label: <FormatMessage id="department.BE09" />,
    children: [
        {
            value: "Techniekers",
            label: <FormatMessage id="department.Techniekers" />
        },
        {
            value: "ProductSolutionsEngineering",
            label: <FormatMessage id="department.ProductSolutionsEngineering" />
        },
        {
            value: "ManagementOPS",
            label: <FormatMessage id="department.ManagementOPS" />
        },
        {
            value: "PreSales",
            label: <FormatMessage id="department.PreSales" />
        },
        {
            value: "TeamCallCenterAndPlanning",
            label: <FormatMessage id="department.TeamCallCenterAndPlanning" />
        },
        {
            value: "Engineering",
            label: <FormatMessage id="department.Engineering" />
        }
    ]
},
{
    value: "BE10",
    label: <FormatMessage id="department.BE10" />,
    children: [
        {
            value: "Management",
            label: <FormatMessage id="department.Management" />
        },
        {
            value: "Operators",
            label: <FormatMessage id="department.Operators" />
        }
    ]
},
{
    value: "BE11",
    label: <FormatMessage id="department.BE11" />,
    children: [
        {
            value: "Veiligheidscoordinatie",
            label: <FormatMessage id="department.Veiligheidscoordinatie" />
        },
        {
            value: "TrainingAndAudit",
            label: <FormatMessage id="department.TrainingAndAudit" />
        }
    ]
},
{
    value: "BE22",
    label: <FormatMessage id="department.BE22" />
},
{
    value: "BE30",
    label: <FormatMessage id="department.BE30" />,
    children: [
        {
            value: "AirportCrew",
            label: <FormatMessage id="department.AirportCrew" />
        },
        {
            value: "AviationSecurity",
            label: <FormatMessage id="department.AviationSecurity" />
        }
    ]
},
{
    value: "BE45",
    label: <FormatMessage id="department.BE45" />,
    children: [
        {
            value: "ElectrabelAndTihange",
            label: <FormatMessage id="department.ElectrabelAndTihange" />
        },
        {
            value: "HigherProfiles",
            label: <FormatMessage id="department.HigherProfiles" />
        },
        {
            value: "FireAndSafety",
            label: <FormatMessage id="department.FireAndSafety" />
        },
        {
            value: "Bussing",
            label: <FormatMessage id="department.Bussing" />
        }
    ]
},
{
    value: "BE47",
    label: <FormatMessage id="department.BE47" />,
    children: [
        {
            value: "OxView",
            label: <FormatMessage id="department.OxView" />
        },
        {
            value: "Materials",
            label: <FormatMessage id="department.Materials" />
        }
    ]
},
{
    value: "N138",
    label: <FormatMessage id="department.N138" />,
    children: [
        {
            value: "FireAndSafetyNL",
            label: <FormatMessage id="department.FireAndSafetyNL" />
        }
    ]
}];
